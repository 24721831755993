import React from 'react'
import * as styles from './css/loanIntroduction.module.styl'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { graphql } from 'gatsby'
import { widthInfo } from '../util/index.js'
// import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { digitalThousandth } from '../util/index'
import birthSvg from '../images/loanIntroduction/birth.svg'
import flagSvg from '../images/loanIntroduction/flag.svg'
import cashSvg from '../images/loanIntroduction/cash.svg'
import { Decimal } from 'decimal.js'

// const { t } = useTranslation()
const width = widthInfo()

class LoanIntroduction extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.initialState
  }

  componentDidUpdate(preProps, preState) {
    const { duration, borrowMoney } = preState
    const state = this.state
    // criteria !== state.criteria
    if (duration !== state.duration || borrowMoney !== state.borrowMoney) {
      this.setState({
        isNeedCalc: true
      })
    }
  }

  get initialState() {
    return {
      criteria: 'age',
      duration: 30,
      maxDuration: 90,
      borrowMoney: 600,
      minBorrowMoney: 600,
      maxBorrowMoney: 25000,
      minDailyInterest: 0.00067, // 日利息
      maxDailyInterest: 0.0056,
      minDailyServiceFee: 0.0002, // 日服务费
      maxDailyServiceFee: 0.0004,
      minTotalRepaymentAmount: 0,
      maxTotalRepaymentAmount: 0,
      minMarkUp: 0,
      maxMarkUp: 0,
      minProcessingFee: 0,
      maxProcessingFee: 0,
      sliderStep: 500,
      criteriaList: [
        {
          name: 'Age 18-60 years',
          value: 'age',
          icon: birthSvg,
        },
        {
          name: 'Pakistani citizen',
          value: 'flag',
          icon: flagSvg
        },
        {
          name: 'Monthly income',
          value: 'cash',
          icon: cashSvg
        },
      ],
      durationList: [
        {
          name: '30 Days',
          value: 30,
        },
        // {
        //   name: '60 Days',
        //   value: 60,
        // },
        // {
        //   name: '90 Days',
        //   value: 90,
        // }
      ],
      isNeedCalc: true,
      // contentEditable: false
    }
  }

  onSliderChange = (value) => {
    this.setState({
      borrowMoney: value,
    })
  }

  changeCriteria = (value) => {
    this.setState({
      criteria: value
    })
  }

  changeDuration = (value) => {
    this.setState({
      duration: value
    })
  }

  calculateLoanLimit = () => {
    /**
     * 计算方式
     * 应还总金额：本金+金额*账期*（最低日利率+最低日费率）~ 本金+金额*账期*（最高日利率+最高日费率）
     * 利息：金额*账期*最低日利率 ~ 金额*账期*最高日利率
     * 服务费：金额*账期*最低日费率 ~ 金额*账期*最高日费率
    */
    const { borrowMoney, duration, minDailyInterest, maxDailyInterest, minDailyServiceFee, maxDailyServiceFee } = this.state

    const mins = new Decimal(minDailyInterest).plus(minDailyServiceFee).toNumber()
    const maxs = new Decimal(maxDailyInterest).plus(maxDailyServiceFee).toNumber()
    console.log(mins, maxs)
    // 应还总金额
    const minTotalRepaymentAmount = new Decimal(borrowMoney).times(duration).times(mins).plus(borrowMoney).ceil().toNumber()
    const maxTotalRepaymentAmount = new Decimal(borrowMoney).times(duration).times(maxs).plus(borrowMoney).ceil().toNumber()
    // 利息
    const minMarkUp = new Decimal(borrowMoney).times(duration).times(minDailyInterest).ceil().toNumber()
    const maxMarkUp = new Decimal(borrowMoney).times(duration).times(maxDailyInterest).ceil().toNumber()
    // 服务费
    const minProcessingFee = new Decimal(borrowMoney).times(duration).times(minDailyServiceFee).ceil().toNumber()
    const maxProcessingFee = new Decimal(borrowMoney).times(duration).times(maxDailyServiceFee).ceil().toNumber()

    const updateState = {
      minTotalRepaymentAmount,
      maxTotalRepaymentAmount,
      minMarkUp,
      maxMarkUp,
      minProcessingFee,
      maxProcessingFee,
    }

    Object.keys(updateState).forEach(item => {
      updateState[item] = digitalThousandth(updateState[item])
    })

    this.setState(updateState)

    this.setState({
      isNeedCalc: false
    })
  }

  // handleInputChange = (event) => {
  //   let borrowMoney = event.target.value
  //   if (borrowMoney > 100000) {
  //     borrowMoney = 100000
  //   }
  //   this.setState({ borrowMoney })
  // }

  // handleInputBlur = (event) => {
  //   const borrowMoney = event.target.value
  //   if (borrowMoney < 800) {
  //     this.setState({
  //       borrowMoney: 800,
  //       contentEditable: false
  //     })
  //   }
  // }

  render() {
    return (
      <Layout>
        <Seo title="LoanIntroduction" />
        <div className={styles.loanIntro}>
          <div className={styles.banner}>
            <div className={`${styles.content}`}>
              <div className={styles.left}>
                <StaticImage
                  className={styles.img}
                  src="../images/loanIntroduction/banner-person.png"
                  alt="person"
                  placeholder="blurred"
                />
              </div>
              <div className={styles.right}>
                <div className={styles.tip}>
                  <h1>Barwaqt Personal Loan</h1>
                  <p>Loan Introduction</p>
                </div>
                <div className={styles.intro}>
                  <p>
                    <span>Barwaqt</span> is an online loan application in Pakistan where users can apply for personal loans up to <span>Rs.{digitalThousandth(this.state.maxBorrowMoney)}</span> anytime and anywhere.
                  </p>
                  <p>Financing limit between <span>Rs.{digitalThousandth(this.state.minBorrowMoney)}-Rs.{digitalThousandth(this.state.maxBorrowMoney)}</span>.</p>
                  <p>Repayment tenure(s) under <span>{this.state.maxDuration} days</span>.(including extended loan)</p>
                  <p>Daily markup rate <span>0.067%-0.56%</span>, with one time processing fee.</p>
                  <p>Penalty will be charged after due date.</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.mobileBanner}>
            <div className={styles.mobileBannerImg}>
              <StaticImage
                className={styles.img}
                src="../images/loanIntroduction/banner-mobile.png"
                alt="person"
                placeholder="blurred"
              />
            </div>
            <div className={styles.desc}>
              <div className={styles.tip}>
                <h1>Barwaqt Personal Loan</h1>
                <p>Loan Introduction</p>
              </div>
              <div className={styles.intro}>
                <p>
                  <span>Barwaqt</span> is an online loan application in Pakistan where users can apply for personal loans up to <span>Rs.{digitalThousandth(this.state.maxBorrowMoney)}</span> anytime and anywhere.
                </p>
                <p>Financing limit between <span>Rs.{digitalThousandth(this.state.minBorrowMoney)}-Rs.{digitalThousandth(this.state.maxBorrowMoney)}</span>.</p>
                <p>Repayment tenure(s) under <span>{this.state.maxDuration} days</span>.(including extended loan)</p>
                <p>Daily markup rate <span>0.067%-0.56%</span>, with one time processing fee.</p>
                <p>Penalty will be charged after due date.</p>
              </div>
            </div>
          </div>
          <article className={styles.criteria}>
            <h3 className={styles.title}>Eligibility Criteria</h3>
            <section className={`${styles.list} ${styles.container}`}>
              <ul className={styles.items}>
                {
                  this.state.criteriaList.map((item, key) => {
                    return (
                      <li onClick={this.changeCriteria.bind(this, item.value)} key={key} className={`${styles.item} ${this.state.criteria === item.value ? styles.active : ''}`}>
                        <p>{item.name}</p>
                        <img src={item.icon} alt="icon" />
                      </li>
                    )
                  })
                }
              </ul>
            </section>
          </article>

          <article className={styles.calculator}>
            <h3 className={styles.title}>Loan Calculator</h3>
            <section className={`${styles.wrapper} ${styles.container}`}>
              <div className={`${styles.options} ${!this.state.isNeedCalc ? styles.borderBottom1 : ''}`}>
                <ul className={styles.optionsList}>
                  <li>
                    <div className={styles.label}>
                      Daily Markup Rate: <span>0.067%-0.56%</span>
                      <p>（The markup is for reference only.
                        Determined according to the actual credit status of the customer）</p>
                    </div>
                    <div className={styles.label}>
                      Processing Fee:
                      <p>Check calculation results for details.</p>
                    </div>
                  </li>
                  <li>
                    <div className={`${styles.borrowMoney} ${styles.value}`}>
                      <div className={styles.borrowMoneyNum}>
                        <h2 className={styles.subtitle}>I Want To Borrow</h2>
                        {/* {
                          this.state.contentEditable ? (
                            <p className={styles.contentEditable}>
                              Rs.
                              <input
                                type="text"
                                value={this.state.borrowMoney}
                                onChange={this.handleInputChange}
                                onBlur={this.handleInputBlur}
                                autoFocus
                              />
                            </p>
                          ) : (
                            <p onClick={() => this.setState({ contentEditable: true })}>Rs.{digitalThousandth(this.state.borrowMoney)}</p>
                          )
                        } */}
                        <p>Rs.{digitalThousandth(this.state.borrowMoney)}</p>
                      </div>
                      <Slider
                        railStyle={{ backgroundColor: 'rgba(0,0,0,0.15)', borderRadius: '20px' }}
                        trackStyle={{ backgroundColor: '#00C388', borderRadius: '20px' }}
                        handleStyle={{
                          borderColor: '#00C388',
                          height: width > 1024 ? 24 : '1.4rem',
                          width: width > 1024 ? 24 : '1.4rem',
                          marginLeft: 0,
                          marginTop: width > 1024 ? -10 : '-.5rem',
                          backgroundColor: '#00C388',
                        }}
                        value={this.state.borrowMoney}
                        min={this.state.minBorrowMoney}
                        max={this.state.maxBorrowMoney}
                        step={this.state.sliderStep}
                        onChange={this.onSliderChange}
                      />
                      <div className={styles.sliderEnds}>
                        <span>Rs.{digitalThousandth(this.state.minBorrowMoney)}</span>
                        <span>Rs.{digitalThousandth(this.state.maxBorrowMoney)}</span>
                      </div>
                    </div>
                    <div className={`${styles.duration} ${styles.value}`}>
                      <h2 className={styles.subtitle}>
                        Loan Duration
                      </h2>
                      <ul className={styles.radios}>
                        {
                          this.state.durationList.map((item, key) => {
                            return (
                              <li onClick={this.changeDuration.bind(this, item.value)} key={key} className={`${styles.item} ${this.state.duration === item.value ? styles.active : ''}`}>
                                {item.name}
                              </li>
                            )
                          })
                        }
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
              {
                this.state.isNeedCalc ? (
                  <div className={styles.checkResultBtn}>
                    <button onClick={this.calculateLoanLimit.bind(this)}>Check calculation results for details</button>
                  </div>
                ) : null
              }
              {
                !this.state.isNeedCalc ? (
                  <div className={styles.results}>
                    <ul>
                      <li>
                        <p className={styles.label}>Total Repayment Amount</p>
                        <p className={styles.value}>Rs.{this.state.minTotalRepaymentAmount}~Rs.{this.state.maxTotalRepaymentAmount}</p>
                      </li>
                      <li>
                        <p className={styles.label}>Markup</p>
                        <p className={styles.value}>Rs.{this.state.minMarkUp}~Rs.{this.state.maxMarkUp}</p>
                      </li>
                      <li>
                        <p className={styles.label}>Processing Fee</p>
                        <p className={styles.value}>Rs.{this.state.minProcessingFee}~Rs.{this.state.maxProcessingFee}</p>
                      </li>
                    </ul>
                    <p className={styles.tip}>(The actual display in the APP shall prevail.)</p>
                  </div>
                ) : null
              }

            </section>
          </article>
        </div>
      </Layout>
    )
  }
}

export default LoanIntroduction

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["about", "header"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
